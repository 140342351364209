import RouteNavigation from "./Routes/RouteNavigation";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./ToastStyles.css";
import "chart.js/auto";
import ChatWidget from "./Components/SupportChat/ChatWidget";
import { useLocation } from "react-router-dom";
import { isAdminLoggedIn } from "./Services/userService";
import { selectColors, selectFavicon, selectLoading, selectLoadingColors, selectSiteTitle } from "./Redux/Slices/generalSlice";
import { useSelector } from "./Redux/reduxHooks";
import { useEffect } from "react";


const App = () => {
	const googleClientId = process.env.REACT_APP_GOOGLE_OAUTH_ID ?? "";
	const location = useLocation();
	const title = useSelector(selectSiteTitle);
	const favicon = useSelector(selectFavicon);
	const loading = useSelector(selectLoading);
	const loadingColors = useSelector(selectLoadingColors);
	const colors = useSelector(selectColors);
	const apptheme = theme(colors)

	const hideChatWidgetOnPaths = ['/chat/video-chat']
	const shouldShowChatWidget = !hideChatWidgetOnPaths.includes(location.pathname);

	// useEffect to update the document title
	useEffect(() => {
		if (title)
			document.title = title;
		else
			document.title = '\u200B'
	}, [title]); // The effect depends on the `title` state

	// useEffect to update the favicon when it changes
	useEffect(() => {
		if (favicon)
			updateFavicon(favicon);
	}, [favicon]); // The effect depends on the `favicon` state

	// Function to update the favicon
	const updateFavicon = (iconURL: string): void => {
		let link = document.querySelector("link[rel*='icon']") as HTMLLinkElement | null;

		if (!link) {
			link = document.createElement('link');
			link.type = 'image/x-icon';
			link.rel = 'shortcut icon';
			document.getElementsByTagName('head')[0].appendChild(link);
		}

		link.href = iconURL;
	};
	if (loading || loadingColors) return <></>
	return (

		<ThemeProvider theme={apptheme}>
			<GoogleOAuthProvider clientId={googleClientId}>
				<Provider store={store}>
					<RouteNavigation />
					{shouldShowChatWidget && isAdminLoggedIn() && <ChatWidget />}
					<ToastContainer hideProgressBar autoClose={2500} />
				</Provider>
			</GoogleOAuthProvider>
		</ThemeProvider>
	);
};

export default App;
